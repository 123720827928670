<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    v-if="
      !!states && !!companies && !!allCustomers && !!allLawyers && !!allCases
    "
    :formIsReadOnly="
      ['supercustomer', 'customer'].includes($store.state.AppActiveUser.role)
    "
  />
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase/firebaseConfig";

import FormPage from "@/components/penal-retail-helpers/FormPage";
import establishmentsFormConfig from "@/helpers/establishmentsFormConfig";

import { createRelatedCasesColumns } from "@/helpers/utils";

let collectionName = "establishments";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allLawyers", "allCases"]),
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
  },
  watch: {
    data() {
      if (this.data) {
        this.$bind(
          "companies",
          db
            .collection(`customers/${this.data.customer.id}/companies`)
            .where("deleted", "==", false),
          { wait: true }
        );
      }
    },
  },
  created() {
    this.$bind(
      "data",
      db.collection("establishments").doc(this.$route.params.id),
      { wait: true }
    );

    this.$bind("states", db.collection("states").orderBy("alias"), {
      wait: true,
    });
  },
  data() {
    let rows = establishmentsFormConfig(this, false);

    return {
      states: null,
      companies: null,
      data: null,
      collectionName,
      tabs: [
        { name: `${collectionName}.tabs.general`, rows },
        {
          name: `${collectionName}.tabs.cases`,
          attributes: this.defineCasesTab(),
        },
      ],
    };
  },
  methods: {
    defineCasesTab() {
      let columns = createRelatedCasesColumns(this).filter(
        (c) => !["customer", "establishment"].includes(c.field)
      );

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.allCases.filter(
              (x) => x.establishment.id === this.$route.params.id
            );
          },
          filters: [],
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: (params) => {
              this.$router
                .push("/main/cases/" + params.data.caseId)
                .catch(() => {});
            },
          },
        },
      });

      return config;
    },
    async prevalidate(model, collection, errors) {
      const otherCodes = (
        await collection
          .where("code", "==", model.code)
          .where("customer.id", "==", model.customer.id)
          .get()
      ).docs
        .filter((x) => x.exists)
        .filter((x) => x.id !== this.$route.params.id);
      if (otherCodes.length > 0) {
        errors.add({
          field: "code",
          msg: this.$t(`${collectionName}.validations.code.unique`),
        });
      }
    },
  },
};
</script>
